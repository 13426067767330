/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import tw, { css } from 'twin.macro';
import StructuredText from '@datocms/MyStructuredText';

const DefinitionList = ({
	title,
	description,
	terms,
	disclosedInitially = false,
}) => {
	return (
		<Disclosure
			defaultOpen={disclosedInitially}
			as="div"
			tw="bg-white shadow overflow-hidden sm:rounded-lg">
			{({ open }) => (
				<>
					<div tw="px-4 py-5 sm:px-6">
						<div tw="flex justify-between">
							<p tw="m-0! p-0! text-lg leading-6 font-medium text-gray-900">
								{title}
							</p>
							<Disclosure.Button>
								<span tw="ml-6 h-7 flex items-center">
									<ChevronDownIcon
										css={[
											open ? tw`-rotate-180` : tw`rotate-0`,
											tw`h-6 w-6 transform`,
										]}
										aria-hidden="true"
									/>
								</span>
							</Disclosure.Button>
						</div>
						{description && (
							<p tw="m-0! p-0! mt-1! max-w-2xl text-sm text-gray-500">
								{description}
							</p>
						)}
					</div>
					<Disclosure.Panel>
						<div tw="border-t border-gray-200 px-4 py-5 sm:p-0">
							<dl tw="sm:divide-y sm:divide-gray-200">
								{terms.map(({ term, definition }) => {
									return (
										<div
											key={term}
											tw="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
											<dt tw="text-sm font-medium text-gray-500">{term}</dt>
											<dd
												tw="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
												css={css`
													p {
														${tw`my-0!`}
													}
												`}>
												<StructuredText data={definition} />
											</dd>
										</div>
									);
								})}
							</dl>
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
};

export default DefinitionList;
