import tw, { styled } from 'twin.macro';
import StructuredText from '@datocms/MyStructuredTextWithBlocks';
import type { StructuredTextField } from '@datocms/types';
import { outerContainer } from 'styles';

export const OuterContainer = styled.div(
	outerContainer,
	tw`px-4 py-12 sm:px-6 lg:px-8`
);
export const InnerContainer = styled.div(tw`max-w-4xl mx-auto`);

type ProseProps = {
	content: StructuredTextField;
};
const Prose = ({ content }: ProseProps) => {
	return (
		<article tw="prose prose-lg max-w-none -sm:break-words">
			<StructuredText data={content} />
		</article>
	);
};

export default Prose;
