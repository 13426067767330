import { StructuredText, Image } from 'react-datocms';
import type {
	StructuredTextPropTypes,
	StructuredTextGraphQlResponseRecord,
} from 'react-datocms';
import tw, { css } from 'twin.macro';
import Copyright from 'components/CopyrightNotice';
import DefinitionList from 'components/DefinitionList';
import YoutubeEmbed from 'components/YoutubeLiteEmbed';
import renderLink from './LinkToRecord';
import globalRules from './render-rules';
type ComponentType =
	StructuredTextPropTypes<StructuredTextGraphQlResponseRecord>['renderBlock'];

const Block: ComponentType = ({ record: { __typename, ...data } }) => {
	if (__typename === 'HtmlBlockRecord') {
		const caption = (
			<figcaption>
				<StructuredText
					data={data.caption as any}
					customRules={globalRules}
					renderLinkToRecord={renderLink}
				/>
			</figcaption>
		);
		return (
			<figure>
				{data.displayCaptionAbove && caption}
				<div
					tw="overflow-x-auto"
					dangerouslySetInnerHTML={{ __html: data.html as string }}
				/>
				{!data.displayCaptionAbove && caption}
			</figure>
		);
	}
	if (__typename === 'ImageBlockRecord') {
		const caption = (
			<figcaption
				css={[
					!data.displayCaptionAbove && tw`mt-0!`,
					css`
						p {
							${tw`my-1.5!`}
						}
					`,
				]}>
				{!data.displayCaptionAbove && <Copyright {...data.image} />}
				<StructuredText
					data={data.caption as any}
					customRules={globalRules}
					renderLinkToRecord={renderLink}
				/>
				{data.displayCaptionAbove && <Copyright {...data.image} />}
			</figcaption>
		);
		return (
			<figure
				css={css`
					img {
						${tw`m-0!`}
					}
				`}>
				{data.displayCaptionAbove && caption}
				<Image data={(data as any).image.responsiveImage} />
				{!data.displayCaptionAbove && caption}
			</figure>
		);
	}
	if (__typename === 'DefinitionListRecord') {
		return (
			<DefinitionList
				title={data.title}
				description={data.description}
				terms={data.terms}
			/>
		);
	}
	if (__typename === 'VideoBlockRecord') {
		const { provider, providerUid, title } = (data as any).video;
		if (provider === 'youtube') {
			return <YoutubeEmbed title={title as string} embedId={providerUid} />;
		}
	}
	return <></>;
};

export default Block;
