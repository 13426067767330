import tw from 'twin.macro';
import Link from 'components/SmartLink';

const knownAuthorsUrlKeys = [
	'unsplash_author_url',
	'unsplash_referrer',
	'author_url',
	'photographer_url',
	'photographer_website',
];

const knownCopyrightUrlKeys = [
	'unsplash_url',
	'unsplash_copyright_url',
	'copyright_url',
	'photographer_url',
	'photographer_website',
];

const findUrls = (data) => {
	const [, authorUrl = ''] =
		Object.entries<string>(data).find(([key]) =>
			knownAuthorsUrlKeys.includes(key)
		) ?? [];

	const [, copyrightUrl = ''] =
		Object.entries<string>(data).find(([key]) =>
			knownCopyrightUrlKeys.includes(key)
		) ?? [];

	return { authorUrl, copyrightUrl };
};

const textStyle = tw`text-gray-400! no-underline! font-normal!`;
const CopyrightNotice = (props) => {
	const { copyright, author, customData = {} } = props ?? {};
	const { authorUrl, copyrightUrl } = findUrls(customData);

	const Author = authorUrl ? Link : 'span';
	const Copyright = copyrightUrl ? Link : 'span';
	const AuthorOrCopyright = authorUrl || copyrightUrl ? Link : 'span';

	const authorAndCopyrightElement = author && copyright && (
		<>
			<Author css={textStyle} href={authorUrl}>
				{author} /
			</Author>
			<Copyright
				css={textStyle}
				href={copyrightUrl}>{` ©️ ${copyright}`}</Copyright>
		</>
	);

	const authorOrCopyrightElement = (author || copyright) && (
		<AuthorOrCopyright css={textStyle} href={authorUrl || copyrightUrl}>
			{` ©️ ${author || copyright}`}
		</AuthorOrCopyright>
	);

	return authorAndCopyrightElement || authorOrCopyrightElement || <></>;
};

export default CopyrightNotice;
